import React from 'react'
import { graphql, Link, useStaticQuery } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import { css } from 'linaria'
import { Query } from '~/types/graphql-types'
import { STYLE } from '~/utils/constant'
import { getGatsbyImageData, rem } from '~/utils/helper'

const newsRecommendStyle = css`
  margin-top: 8px;
  padding-bottom: 8px;

  & > .item:not(:first-of-type) {
    border-top: 1px solid var(--color-background);
  }

  & > .item > .button {
    display: flex;
    align-items: flex-start;
    padding: 16px 0 14px;
    color: var(--color-text);
    text-decoration: none;
  }

  & > .item > .button > .image {
    width: 27%;
    height: 100%;
    margin-right: 8px;

    @media screen and (${STYLE.MEDIA.TABLET}) {
      width: 37%;
    }
  }

  & > .item > .button > .title {
    display: -webkit-box;
    width: calc(73% - 8px);
    overflow: hidden;
    font-size: ${rem(12)};
    font-weight: bold;
    letter-spacing: -0.6px;
    line-height: 1.33;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;

    @media screen and (${STYLE.MEDIA.TABLET}) {
      width: calc(63% - 8px);
    }
  }
`

const NewsRecommendComponent = () => {
  const data: Query = useStaticQuery(
    graphql`
      query ($osusume_list: BooleanQueryOperatorInput = { eq: true }) {
        allFile(filter: { name: { eq: "cms-news" } }) {
          edges {
            node {
              childrenImageSharp {
                gatsbyImageData(placeholder: NONE)
              }
              url
              name
              id
            }
          }
        }
        allMicrocmsStgNews(filter: { osusume_list: $osusume_list }) {
          edges {
            node {
              id
              image_catch {
                url
              }
              title
              stgNewsId
            }
          }
        }
      }
    `,
  )

  return (
    <ul className={newsRecommendStyle}>
      {data &&
        data.allMicrocmsStgNews.edges.map((edge) => {
          const item = edge.node
          if (!item || !item.stgNewsId || !item.image_catch || !item.image_catch.url || !item.title) return
          const gatsbyImageData = getGatsbyImageData(data.allFile, item.image_catch.url)
          return (
            <li className="item" key={item.id}>
              <Link className="button" to={`/news/${item.stgNewsId}`}>
                <GatsbyImage className="image" image={gatsbyImageData} alt={item.title} />
                <p className="title">{item.title}</p>
              </Link>
            </li>
          )
        })}
    </ul>
  )
}

export default NewsRecommendComponent
