import React from 'react'
import { graphql, Link, useStaticQuery } from 'gatsby'
import { css } from 'linaria'
import { rem } from '~/utils/helper'

interface NewsTagsTypes {
  allMicrocmsStgNewsTags: {
    edges: {
      node: {
        id: string
        name: string
        stgNewsTagsId: string
      }
    }[]
  }
}

const newsTagsStyle = css`
  display: flex;
  flex-wrap: wrap;
  margin-top: 8px;
  padding-bottom: 16px;
  column-gap: 4px;
  row-gap: 8px;

  & > .item {
    max-width: 100%;
  }

  & > .item > .button {
    display: block;
    position: relative;
    padding: 4px 8px;
    overflow: hidden;
    border: 1px solid currentColor;
    border-radius: 100px;
    color: var(--color-text);
    font-size: ${rem(12)};
    letter-spacing: 0;
    text-decoration: none;
    text-overflow: ellipsis;
    white-space: nowrap;

    &::before {
      content: '#';
      padding-right: 2px;
    }
  }
`

const NewsTagsComponent = () => {
  const data: NewsTagsTypes = useStaticQuery(
    graphql`
      {
        allMicrocmsStgNewsTags {
          edges {
            node {
              id
              name
              stgNewsTagsId
            }
          }
        }
      }
    `,
  )

  return (
    <ul className={newsTagsStyle}>
      {data?.allMicrocmsStgNewsTags.edges.map((item) => {
        const tag = item.node
        return (
          <li className="item" key={tag.id}>
            <Link className="button" to={`/news/tag/${tag.stgNewsTagsId}`}>
              {tag.name}
            </Link>
          </li>
        )
      })}
    </ul>
  )
}

export default NewsTagsComponent
