import React from 'react'
import { Helmet } from 'react-helmet'
import { graphql, Link } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import { css } from 'linaria'
import Layout from '~/components/Common/Layout'
import SmoothScroll from '~/components/Common/SmoothScroll'
import Banner from '~/components/News/Banner'
import Category from '~/components/News/Category'
import Recommend from '~/components/News/Recommend'
import Tag from '~/components/News/Tag'
import IconRelease from '~/images/icon/releasedate.svg'
import IconTaglist from '~/images/icon/taglist.svg'
import IconUpdate from '~/images/icon/updatedate.svg'
import { Query } from '~/types/graphql-types'
import { STYLE } from '~/utils/constant'
import { convertHtmlToText, getGatsbyImageData, rem, replaceImageAndBlogcard } from '~/utils/helper'

interface Toc {
  id: string
  tag: string
  title: string
}

const newsDetailStyle = css`
  max-width: var(--width-max-content);
  margin-top: calc(var(--height-header) + 56px);

  @media screen and (${STYLE.MEDIA.TABLET}) {
    margin-top: 80px;
    margin-right: auto;
    margin-left: auto;
  }

  @media screen and (${STYLE.MEDIA.PC}) {
    padding-right: 2px;
    padding-left: 2px;
  }

  & > .contentwrapper {
    max-width: var(--width-max-content);
    margin-right: auto;
    margin-left: auto;
    padding-right: 34px;
    padding-left: 34px;

    @media screen and (${STYLE.MEDIA.TABLET}) {
      display: grid;
      grid-template-areas:
        'body .'
        'body .'
        'body .'
        'body banner'
        '. banner';
      grid-template-columns: 1fr 188px;
      grid-template-rows: auto auto auto 1fr;
      column-gap: 16px;
      row-gap: 16px;
    }

    @media screen and (${STYLE.MEDIA.PC}) {
      grid-template-columns: 1fr 236px;
      grid-template-rows: auto auto auto 1fr;
      padding-right: 0;
      padding-left: 0;
    }
  }

  & > .contentwrapper > .content {
    width: 100vw;
    margin: 0 calc(50% - 50vw);

    @media screen and (${STYLE.MEDIA.TABLET}) {
      grid-area: body;
      width: auto;
      margin: 0;
    }
  }

  & > .contentwrapper > .content > .body {
    padding: 32px 24px 80px;
    border-radius: 16px;
    background: var(--color-background-bright);

    @media screen and (${STYLE.MEDIA.TABLET}) {
      padding: 48px 48px 80px;
    }
  }

  & > .contentwrapper > .content > .body > .title {
    font-size: ${rem(32)};
    font-weight: bold;
  }

  & > .contentwrapper > .content > .body > .banner {
    margin-top: 48px;

    & img {
      max-width: 100%;
      height: auto;
    }
  }

  & > .contentwrapper > .content > .body > .buttonwrapper {
    display: flex;
    justify-content: center;
  }

  & > .contentwrapper > .content > .body > .buttonwrapper > .button {
    min-width: 288px;
    margin-top: 48px;
    padding: 19px 62px 18px;
    border: 1px solid var(--color-background-key);
    border-radius: 32px;
    font-size: ${rem(16)};
    font-weight: bold;
    letter-spacing: 3.2px;
    text-align: center;
    text-decoration: none;
  }
`

const tocNewsDetailStyle = css`
  margin-top: 20px;
  padding: 20px;
  border-radius: 8px;
  background-color: var(--color-background-gray50);

  @media screen and (${STYLE.MEDIA.TABLET}) {
    padding: 30px;
  }

  & > .title {
    color: var(--color-key);
    font-weight: 700;
  }

  & > .list > .item {
    margin: 15px 0;
    padding-top: 8px;
    font-size: ${rem(14)};
    font-weight: 700;
    list-style: none;
    text-decoration: none;

    &.-h2 {
      &:not(:first-of-type) {
        margin: 16px 0 8px;
        padding-top: 20px;
        border-top: 1px solid #dfdfdf;
      }
    }

    &.-h3 {
      margin: 0 0 16px 24px;
      padding-left: 1em;
      list-style: disc;
      text-indent: -1em;

      @media screen and (${STYLE.MEDIA.TABLET}) {
        margin: 0 0 5px 30px;
      }

      &::marker {
        color: var(--color-grey600);
        font-size: ${rem(8)};
      }
    }
  }

  & > .list > .item > .button {
    color: var(--color-grey600);
    text-decoration: none;
  }
`

const infoNewsDetailStyle = css`
  margin-top: 22px;

  @media screen and (${STYLE.MEDIA.TABLET}) {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-top: 16px;
  }

  & > .category {
    display: block;
    width: 9rem;
    padding: 3px 7px 5px;
    border: 1px solid var(--color-background-key);
    border-radius: 4px;
    color: var(--color-background-key);
    font-size: ${rem(12)};
    line-height: 1.33;
    text-align: center;
    text-decoration: none;

    @media screen and (${STYLE.MEDIA.TABLET}) {
      display: inline-block;
      margin-right: 24px;
    }
  }

  & > .open_date {
    position: relative;
    padding-left: 18px;
    font-size: ${rem(12)};
    font-weight: bold;
    letter-spacing: 0;

    @media screen and (${STYLE.MEDIA.TABLET}) {
      letter-spacing: 1.2px;
    }
  }

  & > .open_date > .icon {
    display: inline-block;
    position: absolute;
    top: 50%;
    left: 0;
    width: 15px;
    height: 15px;
    transform: translateY(-50%);

    & path {
      fill: currentColor;
    }
  }

  & > .last_date {
    position: relative;
    margin-left: 15px;
    padding-left: 18px;
    font-size: ${rem(12)};
    font-weight: bold;
    letter-spacing: 0;

    @media screen and (${STYLE.MEDIA.TABLET}) {
      letter-spacing: 1.2px;
    }
  }

  & > .last_date > .icon {
    display: inline-block;
    position: absolute;
    top: 50%;
    left: 0;
    width: 15px;
    height: 15px;
    transform: translateY(-50%);

    & path {
      fill: currentColor;
    }
  }

  & > .dateModified {
    display: inline-block;
    margin-top: 15px;
    font-size: ${rem(12)};
    font-weight: bold;
    letter-spacing: 0;

    @media screen and (${STYLE.MEDIA.TABLET}) {
      display: block;
      margin-top: 0;
      letter-spacing: 1.2px;
    }
  }

  & > .date {
    display: inline-block;
    margin-top: 15px;
    font-size: ${rem(12)};
    font-weight: bold;
    letter-spacing: 0;

    @media screen and (${STYLE.MEDIA.TABLET}) {
      display: block;
      margin-top: 0;
      padding-left: 2px;
      letter-spacing: 1.2px;
    }
  }
`

const tagNewsDetailStyle = css`
  display: flex;
  margin-top: 14px;
  column-gap: 8px;
  font-size: ${rem(12)};
  row-gap: 8px;

  @media screen and (${STYLE.MEDIA.TABLET}) {
    margin-top: 16px;
  }

  & > .item > .button {
    font-weight: bold;
    text-decoration: none;

    &::before {
      content: '#';
      padding-right: 2px;
    }
  }
`

const contentNewsDetailStyle = css`
  margin-top: 24px;
  line-height: 1.8;

  @media screen and (${STYLE.MEDIA.TABLET}) {
    margin-top: 32px;
  }

  & > .main-content {
    margin-top: 20px;
  }

  & > .sub-content {
    margin-top: 20px;
  }

  & img {
    max-width: 100%;
    height: auto;
  }

  & > .image {
    display: block;
    width: 100%;
    height: auto;
    margin-bottom: 32px;
    border: 1px solid var(--color-bluegray50);

    @media screen and (${STYLE.MEDIA.TABLET}) {
      margin-bottom: 48px;
    }

    &:not(:first-of-type) {
      margin-top: 32px;

      @media screen and (${STYLE.MEDIA.TABLET}) {
        margin-top: 48px;
      }
    }
  }
`

const tagFooterNewsDetailStyle = css`
  align-items: flex-start;
  margin-top: 32px;
  column-gap: 16px;
  font-size: ${rem(12)};

  @media screen and (${STYLE.MEDIA.TABLET}) {
    display: flex;
    margin-top: 48px;
  }

  & > .title {
    display: flex;
    align-items: center;
    column-gap: 4px;
    font-weight: bold;
    line-height: 1;
    white-space: nowrap;

    @media screen and (${STYLE.MEDIA.TABLET}) {
      margin-top: 4px;
    }
  }

  & > .title > .icon {
    width: 20px;
    height: auto;
  }

  & > .body {
    margin-top: 8px;

    @media screen and (${STYLE.MEDIA.TABLET}) {
      flex-grow: 1;
      margin-top: 0;
    }
  }

  & > .body > .list {
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 16px;
    column-gap: 4px;
    row-gap: 8px;
  }

  & > .body > .list > .item {
    max-width: 100%;
  }

  & > .body > .list > .item > .button {
    display: block;
    position: relative;
    padding: 4px 8px;
    overflow: hidden;
    border: 1px solid currentColor;
    border-radius: 100px;
    color: var(--color-text);
    letter-spacing: 0;
    text-decoration: none;
    text-overflow: ellipsis;
    white-space: nowrap;

    &::before {
      content: '#';
      padding-right: 2px;
    }
  }
`

const writerNewsDetailStyle = css`
  display: flex;
  justify-content: space-between;
  margin-top: 32px;
  padding: 10px;
  border-radius: 8px;
  background-color: var(--color-background-gray50);
  color: var(--color-text);

  @media screen and (${STYLE.MEDIA.TABLET}) {
    margin-top: 48px;
    padding: 24px;
  }

  & > .image_writer {
    display: block;
    width: 96px;
    height: 96px;
  }

  & > .image_writer > .image {
    width: 100%;
    height: 100%;
    border-radius: 100px;
    object-fit: contain;
  }

  & > .text_right {
    width: calc(100% - 110px);

    @media screen and (${STYLE.MEDIA.TABLET}) {
      width: calc(100% - 120px);
    }
  }

  & > .text_right > .writer_section {
    display: block;
    margin-bottom: 16px;
    font-size: ${rem(14)};
    line-height: 16px;
  }

  & > .text_right > .writer_name {
    display: block;
    margin-bottom: 8px;
    font-size: ${rem(16)};
    font-weight: 700;
    line-height: 20px;
  }

  & > .text_right > .writer_profile {
    display: block;
    font-size: ${rem(12)};
    line-height: 20px;
  }
`

const newsNavigationStyle = css`
  & > .body {
    margin-top: 64px;
    padding: 16px 24px 0;
    border-radius: 16px;
    background: var(--color-background-bright);

    @media screen and (${STYLE.MEDIA.TABLET}) {
      margin-top: 0;
      margin-right: 0;
      margin-left: 0;
    }
  }

  & > .body > .title {
    color: var(--color-background-key);
    font-size: rem(16px);
    font-weight: bold;
  }
`

export const query = graphql`
  query ($id: String!) {
    allOgp {
      nodes {
        ogTitle
        ogDescription
        ogImage
        url
        id
      }
    }
    allFile(filter: { name: { eq: "cms-news" } }) {
      edges {
        node {
          childrenImageSharp {
            gatsbyImageData(placeholder: NONE)
          }
          url
          name
          id
        }
      }
    }
    microcmsStgNews(id: { eq: $id }) {
      category {
        id
        name
      }
      common_banner {
        banner
      }
      date
      dateModified
      detail
      id
      image_catch {
        url
      }
      image_writer {
        url
      }
      tags {
        id
        name
      }
      title
      seo_title
      seo_description
      toc
      stgNewsId
      sub_detail
      writer_name
      writer_profile
    }
  }
`

const NewsDetailPage = ({ data }: { data: Query }) => {
  const pageData = data.microcmsStgNews
  if (!pageData) return
  const breadcrumb = [
    {
      label: 'お役立ち情報',
      path: '/news',
    },
    {
      label: pageData.title || '',
    },
  ]

  const seo = {
    title: `${pageData.seo_title || pageData.title || ''} | お役立ち情報`,
    description: pageData.seo_description || '',
    ogImage: pageData.image_catch && pageData.image_catch.url ? pageData.image_catch.url : '',
  }
  const structuredData = {
    '@context': 'http://schema.org',
    '@type': 'NewsArticle',
    headline: pageData.title,
    image: [pageData.image_catch && pageData.image_catch.url ? pageData.image_catch.url : null],
    datePublished: pageData.date,
    dateModified: pageData.dateModified || '',
    author: {
      '@type': 'Person',
      name: pageData.writer_name,
    },
    publisher: {
      '@type': 'Organization',
      name: pageData.writer_name,
    },
    description: pageData.sub_detail ? convertHtmlToText(pageData.sub_detail) : '',
  }
  // Note: 本文から見出しを抜き出して目次にする
  const toc = (): Toc[] => {
    if (!pageData.detail) return []
    const items = pageData.detail.match(/<h(2|3)(?: .+?)?>.*?<\/h(2|3)>/g)
    if (!items || !items.length) return []
    const toc: Toc[] = []
    items.forEach((item: string) => {
      const tag = item.substring(1, 3)
      const title = convertHtmlToText(item)
      const id = item.match(/id="(.*?)"/)
      if ((tag === 'h2' || tag === 'h3') && id && id[1] && title) {
        toc.push({
          id: id[1],
          tag,
          title,
        })
      }
    })
    return toc
  }

  const gatsbyImageWriterData =
    pageData.image_writer && pageData.image_writer.url
      ? getGatsbyImageData(data.allFile, pageData.image_writer.url)
      : null
  return (
    <Layout breadcrumb={breadcrumb} seo={seo}>
      {pageData.writer_name && pageData.date && pageData.image_catch && pageData.image_catch.url && (
        <Helmet>
          <script type="application/ld+json">{JSON.stringify(structuredData)}</script>
        </Helmet>
      )}
      <div className={newsDetailStyle}>
        <div className="contentwrapper">
          <div className="content">
            <main className="body">
              <h1 className="title">{pageData.title}</h1>
              <div className={infoNewsDetailStyle}>
                {pageData.category && (
                  <Link className="category" to={`/news/category/${pageData.category.id}`}>
                    {pageData.category.name}
                  </Link>
                )}
                <span className="open_date">
                  <IconRelease className="icon" />
                  公開日
                </span>
                <time className="date">{pageData.date}</time>
                {pageData.dateModified && (
                  <>
                    <span className="last_date">
                      <IconUpdate className="icon" />
                      最終更新日
                    </span>
                    <time className="dateModified">{pageData.dateModified}</time>
                  </>
                )}
              </div>
              {pageData.tags && pageData.tags.length > 0 && (
                <ul className={tagNewsDetailStyle}>
                  {pageData.tags.map((tag) => {
                    if (!tag) return null
                    return (
                      <li className="item" key={tag.id}>
                        <Link className="button" to={`/news/tag/${tag.id}`}>
                          {tag.name}
                        </Link>
                      </li>
                    )
                  })}
                </ul>
              )}
              <div className={contentNewsDetailStyle}>
                {pageData.sub_detail && (
                  <div className="text">{replaceImageAndBlogcard(pageData.sub_detail, data.allFile, data.allOgp)}</div>
                )}
                {pageData.toc && toc().length !== 0 && (
                  <nav className={tocNewsDetailStyle}>
                    <p className="title">CONTENTS</p>
                    <ul className="list">
                      {toc().map((item) => {
                        return (
                          <li className={`item -${item.tag}`} key={item.id}>
                            <SmoothScroll className="button" to={item.id} href={`#${item.id}`}>
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: `${item.title}`,
                                }}
                              />
                            </SmoothScroll>
                          </li>
                        )
                      })}
                    </ul>
                  </nav>
                )}
                {pageData.detail && (
                  <div className="main-content">
                    {replaceImageAndBlogcard(pageData.detail, data.allFile, data.allOgp)}
                  </div>
                )}
              </div>
              {pageData.common_banner && pageData.common_banner.banner && (
                <aside
                  className="banner"
                  dangerouslySetInnerHTML={{
                    __html: `${pageData.common_banner.banner}`,
                  }}
                />
              )}
              {pageData.tags && pageData.tags.length > 0 && (
                <dl className={tagFooterNewsDetailStyle}>
                  <dt className="title">
                    <IconTaglist className="icon" />
                    この記事のタグ
                  </dt>
                  <dd className="body">
                    <ul className="list">
                      {pageData.tags.map((tag) => {
                        if (!tag) return null
                        return (
                          <li className="item" key={tag.id}>
                            <Link className="button" to={`/news/tag/${tag.id}`}>
                              {tag.name}
                            </Link>
                          </li>
                        )
                      })}
                    </ul>
                  </dd>
                </dl>
              )}
              {pageData.writer_name && (
                <footer className={writerNewsDetailStyle}>
                  {gatsbyImageWriterData && (
                    <div className="image_writer">
                      <GatsbyImage className="image" image={gatsbyImageWriterData} alt={pageData.writer_name} />
                    </div>
                  )}
                  <div className="text_right">
                    <p className="writer_section">この記事を書いた人</p>
                    <p className="writer_name">{pageData.writer_name}</p>
                    {pageData.writer_profile && <p className="writer_profile">{pageData.writer_profile}</p>}
                  </div>
                </footer>
              )}
              <div className="buttonwrapper">
                <Link className="button" to="/news">
                  一覧へ戻る
                </Link>
              </div>
            </main>
          </div>

          <aside className={newsNavigationStyle}>
            <div className="body">
              <p className="title">カテゴリ</p>
              <Category />
            </div>
          </aside>
          <aside className={newsNavigationStyle}>
            <div className="body">
              <p className="title">人気のタグ</p>
              <Tag />
            </div>
          </aside>
          <aside className={newsNavigationStyle}>
            <div className="body">
              <p className="title">おすすめ記事</p>
              <Recommend />
            </div>
          </aside>
          <aside className="banner news-banner">
            <Banner />
          </aside>
        </div>
      </div>
    </Layout>
  )
}

export default NewsDetailPage
