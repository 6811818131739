import React from 'react'
import { graphql, Link, useStaticQuery } from 'gatsby'
import IconArrow from '~/images/icon/arrow.svg'
import { css } from 'linaria'
import { rem } from '~/utils/helper'

interface NewsCategoryTypes {
  allMicrocmsStgNewsCats: {
    edges: {
      node: {
        id: string
        name: string
        stgNewsCatsId: string
      }
    }[]
  }
}

const newsCategoryStyle = css`
  margin-top: 8px;
  padding-bottom: 8px;

  & > .item:not(:first-child) {
    border-top: 1px solid var(--color-background);
  }

  & > .item > .button {
    display: block;
    position: relative;
    padding: 12px 10px 12px 0;
    color: var(--color-text);
    font-size: ${rem(14)};
    letter-spacing: 0;
    text-decoration: none;
  }

  & > .item > .button > .icon {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: auto;
    height: 11px;
    margin-top: auto;
    margin-bottom: auto;
    transform: scale(-1, 1);

    & path {
      fill: var(--color-background);
    }
  }
`

const NewsCategoryComponent = () => {
  const data: NewsCategoryTypes = useStaticQuery(
    graphql`
      {
        allMicrocmsStgNewsCats {
          edges {
            node {
              id
              name
              stgNewsCatsId
            }
          }
        }
      }
    `,
  )

  return (
    <ul className={newsCategoryStyle}>
      {data &&
        data.allMicrocmsStgNewsCats.edges.map((item) => {
          const category = item.node
          return (
            <li className="item" key={category.id}>
              <Link className="button" to={`/news/category/${category.stgNewsCatsId}`}>
                {category.name}
                <IconArrow className="icon" />
              </Link>
            </li>
          )
        })}
    </ul>
  )
}

export default NewsCategoryComponent
