import React from 'react'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import { css } from 'linaria'
import { STYLE } from '~/utils/constant'
import { rem } from '~/utils/helper'

const newsDemoStyle = css`
  margin-top: 16px;

  @media screen and (${STYLE.MEDIA.TABLET}) {
    margin-top: 0;
  }

  & > .button {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 32px;
    transition: var(--hover-button-transition);
    border-radius: 16px;
    background: var(--color-gradient-key);
    color: var(--color-text);
    letter-spacing: normal;
    text-decoration: none;
    row-gap: 24px;

    @media screen and (${STYLE.MEDIA.TABLET}) {
      padding: 24px 16px;
    }
  }

  & > .button > .title {
    color: var(--color-text-bright);
    font-size: ${rem(14)};
    font-weight: bold;
  }

  & > .button > .image {
    width: 100%;
    height: auto;
  }

  & > .button > .dummybutton {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: 40px;
    border-radius: 100px;
    border-color: var(--color-border-key);
    background: var(--color-background-bright);
    color: var(--color-text-key);
    font-weight: bold;
  }
`

const newsBannerStyle = css`
  position: sticky;
  top: calc(var(--height-header) + 16px);

  @media screen and (${STYLE.MEDIA.PC}) {
    top: calc(var(--height-header-pc) + 16px);
  }

  & > .banner {
    margin-top: 16px;
  }

  & > .banner > .button {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 32px;
    transition: var(--hover-button-transition);
    border-radius: 16px;
    background: var(--color-background-bright);
    color: var(--color-text);
    letter-spacing: normal;
    text-decoration: none;
    row-gap: 8px;

    @media screen and (${STYLE.MEDIA.TABLET}) {
      padding: 24px 16px;
    }

    @media screen and (${STYLE.MEDIA.PC}) {
      padding: 24px;
    }

    &:hover {
      opacity: var(--hover-button-opacity);
    }
  }

  & > .banner > .button > .title {
    font-size: ${rem(16)};
    font-weight: bold;
  }

  & > .banner.-demo > .button > .title {
    color: var(--color-text-bright);
    font-size: ${rem(14)};
  }

  & > .banner > .button > .image {
    width: 100%;
    height: auto;
  }

  & > .banner > .button > .button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: 40px;
    border-radius: 100px;
    border-color: var(--color-border-key);
    background-color: var(--color-background-key);
    color: var(--color-text-bright);
    font-weight: bold;
  }
`

const NewsBannerComponent = () => {
  return (
    <>
      <div className={newsDemoStyle}>
        <Link className="button" to="/inquiry">
          <p className="title">
            リファレンスチェックサービス
            <br />
            ASHIATO（アシアト）
          </p>
          <StaticImage className="image" src="../../images/news/demo.png" placeholder="none" alt="デモ画面をみる" />
          <p className="dummybutton">デモ画面をみる</p>
        </Link>
      </div>

      <ul className={newsBannerStyle}>
        <li className="banner">
          <Link to="/resources/mqr0jahp-r1" className="button">
            <StaticImage
              className="image"
              src="../../images/news/banner01.jpg"
              placeholder="none"
              alt="「リファレンスチェックのすべて」"
            />
            <p className="title">「リファレンスチェックのすべて」</p>
            <p className="button">資料ダウンロード</p>
          </Link>
        </li>
        <li className="banner">
          <Link to="/resources/sv_je8ueo" className="button">
            <StaticImage
              className="image"
              src="../../images/news/banner02.jpg"
              placeholder="none"
              alt="高い精度で、活躍人材を見極められる面接方法"
            />
            <p className="title">高い精度で、活躍人材を見極められる面接方法</p>
            <p className="button">資料ダウンロード</p>
          </Link>
        </li>
        <li className="banner">
          <Link to="/resources/bm5rhxva81h" className="button">
            <StaticImage
              className="image"
              src="../../images/news/banner03.jpg"
              placeholder="none"
              alt="なぜ人は辞めるのか？退職を科学する"
            />
            <p className="title">なぜ人は辞めるのか？退職を科学する</p>
            <p className="button">資料ダウンロード</p>
          </Link>
        </li>
      </ul>
    </>
  )
}

export default NewsBannerComponent
